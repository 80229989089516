.uva-skeleton {
  padding: 0px !important;
  margin-bottom: 16px !important;
}

.uva-skeleton-paragraph {
  height: 8rem !important;
}

.uva-skeleton-panel {
  min-height: 24rem;
}

.uva-skeleton-panel-small {
  min-height: 12rem !important;
}

.uva-skeleton-graph {
  min-height: 20rem;
  margin-bottom: 0px;
}

.uva-skeleton-table {
  min-height: 12rem;  
}

.uva-skeleton-table-header {
  min-height: 5rem;
}

.uva-skeleton-tabs {
  height: 2rem;
  padding: 1.5rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-bottom: 2px !important;
}

.uva-skeleton-card {
  border-radius: 10px;
  min-height: 500px;
}

