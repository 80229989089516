#MonitoringPage {

  #UVAMapboxUberContainer {
    position: relative;
  }

  #UVAEnvironmentalMetricSelectorContainer {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
  }

  #FloorSwitcherWrapper {
    position: absolute;
    z-index: 2;
    left: 10px;
    top: 10px;
  }

  #UVAEnvironmentalMetricSelector {
    position: relative;
    width: 200px;
  }

  #UVAMapboxContainer {
    position: relative;
  }

  #UVAMonitoringAlertStack {
    margin-bottom: 1rem;
  }

} 
