#UvaTracksPage {
    .page-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .detail-field {
        flex-grow: 1;
    }

    .track-nickname {
        font-weight: bold;
    }

    .track-details {
        display: flex;
        flex-direction: column;
    }

    .track-buttons {
        margin-top: 1rem;
        display: flex;
        justify-content: flex-end;

        button {
            margin-left: 1rem;
        }
    }
}