.uva-appbar-link-dark {
  color: #fff;
}
.uva-appbar-link-light {
  color: #fff;
}

.uva-mobile-menu-title {
  text-align: center;
}

#UVADesktopMenu {
  display: none;
}

.uva-account-menu-item {
  display: block;
  float: right;
  margin-right: 0px !important;
}

#UVAToolbar {
  width: 100%;
  #UVABranding {
    display: inline-block;
    width: 168px;
    margin: auto auto;
    vertical-align: middle;
    .uva-branding-logo {
      display: inline-block;
      vertical-align: middle;
      width: 31.5px;
      height: 25px;
    }
    .uva-branding-app-name {
      margin-left: 0.5rem;
      vertical-align: middle;
      display: inline-block;
    }
  }

  .uva-mobile-menu-title {
    text-align: center;
    padding: 0.5rem;
  }
  
  .uva-mobile-menu-icon {
    margin-right: 2px;
    display: block
  }
  
}

#UVAMobileAccountMenuContainer {
  display: inline-block;
  position: fixed;
  right: 0;
  top: 0;
  padding: 6px;
  z-index: 100010;
}

@media screen and (min-width: 940px) {
  #UVAMobileAccountMenuContainer {
    display: none;
  }

  #UVAToolbar {
    #UVADesktopMenu {
      margin-right: 2rem;
    }

    .uva-mobile-menu-icon {
      display: none !important;
    }

    #UVADesktopMenu {
      display: inline;
      float: right;
      width: 85%;

      #UVADesktopAccountMenuContainer {
        display: inline-block;
        position: fixed;
        right: 0;
        top: 0;
        padding: 10px;
        z-index: 100010;
      }

      .uva-desktop-menu-item {
        display: inline;
        float: right;
        color: #fff
      }
    }

    #UVABranding {
      display: block;
      float: left;
      min-width: 200px;
      margin-left: 0px;
      .uva-branding-logo {
        display: inline-block;
        vertical-align: middle;
        width: 63px;
        height: 50px;
      }
      .uva-branding-app-name {
        margin-left: 0.5rem;
        vertical-align: middle;
        display: inline-block;
      }
    }
  }
 
  

}

