@import url('./core/typography/index.scss');
@import url('./core/cursors/index.scss');
@import url('./core/header/index.scss');
@import url('./core/footer/index.scss');
@import url('./core/forms/index.scss');
@import url('./core/tables/index.scss');
@import url('./core/skeletons/index.scss');
@import url('./components/pssq-simulator-filter-panel.scss');
@import url('./components/simulation-results-graph.scss');
@import url('./components/pssq-simulater-page.component.scss');
@import url('./components//mongo-chart.component.scss');
@import url('./components/tracks-page.component.scss');
@import url('./components/monitoring-page.component.scss');

@import '~mapbox-gl/dist/mapbox-gl.css';

main {
  margin: 4rem 2rem;
}

.uva-params-panel, .uva-form-card {
  margin-bottom: 1rem;
  width: 100%;
}

.uva-auth-card {
  max-width: 640px;
  margin: 10rem auto;
  text-align: center;
  padding: 3rem;
}

.uva-loading-shim {
  z-index: 1000;
}

.uva-is-hidden {
  display: none;
}

// .uva-page-title {
//   margin-top: 5rem;
// }

.flex-row-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
