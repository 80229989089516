.uva-filter-panel-card {
  min-height: 240px;
  margin: .25rem;
}

.uva-filter-panel-select {
  width: 100%;
}

#uvaFilterPanel {
  width: 100%;
}

// This style is applied on the currently selected item in a select but also on items displayed in popup menu.
// Popup menu is not attached to parent select element so this style has to be global.
.room-select-value {
  display: flex;
  flex-direction: column;

  .room-desc {
      font-size: 0.8rem;
  }
}