.uva-form-control {
  margin: .5rem 0 !important;
}

// FIXME: No responsive
fieldset {
  .uva-form-control-inline {
    width: 49%;
  }
  .uva-form-control-inline-sm {
    width: 32%;
  }
  .uva-form-control-inline:first-of-type {
    margin-right: .6rem !important;
  }
  .uva-form-control-inline:last-of-type {
    margin-left: .6rem !important;
  }

  border-radius: 4px;
  margin-bottom: 2rem;
}

.uva-form-element-actions,
.uva-form-actions {
  float: right;
  a {
    display: block;
    float: right;
  }
}

.uva-entity-picker-actions {
  display: flex;
  justify-content: space-between;
}

.uva-form-actions {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.uva-alert,
.uva-form-card {
  // margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
}

.uva-date-range-control {
  display: flex;
  justify-content: space-around;
}

.uva-date-time-separator {
  display: inline-block;
  margin-top: .8rem;
  height: 100%;
  text-align: center;
}
